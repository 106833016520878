import React, { useEffect, useRef, useState } from "react";
import {
  getEnableUsersCount,
  getEnableUsers,
} from "../../apis/SettingApis";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import {
  Spin,
} from "antd";
import {
  UPDATE_ASSIGNING_PAGE,
} from "../../utils/constants";
import { useSelector } from "react-redux";
import { Paginator } from "primereact/paginator";
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import UpdateProjectCapacityDialog from "./dialog/UpdateProjectCapacityDialog";
const ProjectCapacity = () => {
  const [users, setUsers] = useState([]);
  const [apiLoading, setApiLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [visibleProjectCapacityDialog, setVisibleProjectCapacityDialog] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [basicFirst, setBasicFirst] = useState(0);
  const [totalRows, setTotalRows] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalRecords, setTotalRecords] = useState();

  const { currentUser = {} } = useSelector(({ app }) => app);
  const dt = useRef(null);
  const toast = useRef(null);

  const fetchPermissions = () => {
    const uniquePermissions = {};
    if (currentUser && currentUser.roles) {
      currentUser.roles.forEach((role) => {
        role.permissions.forEach(
          (permission) => (uniquePermissions[permission.id] = permission)
        );
      });
      setPermissions(Object.values(uniquePermissions));
    }
  };

  const changeVisibleProjectCapacityDialog = (visible) => {
    setVisibleProjectCapacityDialog(visible);
    fetchUsers(pageNumber, totalRows);
  }

  useEffect(() => {
    fetchPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const fetchUserCount = () => {
    if (pageNumber >= 0 && totalRows >= 10) {
      setApiLoading(true);
      getEnableUsersCount()
        .then((res) => {
          if (res.status === 200) {
            setTotalRecords(res.data);
            fetchUsers(pageNumber, totalRows);
            setApiLoading(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: res.response.data,
              life: 3000,
            });
            setApiLoading(false);
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: e,
            life: 3000,
          });
          setApiLoading(false);
        });
    }
  };

  const fetchUsers = (current, pageSize) => {
    setApiLoading(true);
    getEnableUsers(current, pageSize)
      .then((response) => {
        if (response.status === 200) {
          response.data = response.data.sort((u1, u2) => {
            return u1.username.localeCompare(u2.username);
          });
          setUsers(response.data);
          setApiLoading(false);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: response.response.data,
            life: 3000,
          });
          setApiLoading(false);
        }
      })
      .catch((e) => {
        toast.current.show({
          severity: "error",
          summary: "Status",
          detail: e,
          life: 3000,
        });
        setApiLoading(false);
      });
    return false;
  };

  useEffect(() => {
  }, []);

  useEffect(() => {
    fetchUserCount();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [pageNumber, totalRows]);

  const onBasicPageChange = (event) => {
    setApiLoading(true);
    setBasicFirst(event.first);
    setPageNumber(event.page);
    setTotalRows(event.rows);
    setApiLoading(false);
  };

  const COLORS = {
    monday: "#FFF0F5 ",    // Light red
    tuesday: "#F5FFFA ",   // Light green
    wednesday: "#F0F8FF ", // Light blue
    thursday: "#F0FFF0 ",  // Light yellow
    friday: "#FFF5EE "     // Light gray
  };

  const headerGroup = (
    <ColumnGroup>
      <Row>
        <Column header="Id" rowSpan={2} />
        <Column header="Username" rowSpan={2} />
        <Column header="Name" rowSpan={2} />
        <Column header="Can Autocad" rowSpan={2} />
        <Column header="Level" rowSpan={2} />
        <Column header="Unavailable Days" rowSpan={2} />
        <Column header="Monday" colSpan={3} />
        <Column header="Tuesday" colSpan={3} />
        <Column header="Wednesday" colSpan={3} />
        <Column header="Thursday" colSpan={3} />
        <Column header="Friday" colSpan={3} />
        <Column header="Action" rowSpan={2} />
      </Row>

      <Row>
        <Column header="Contractor" />
        <Column header="Qcer" />
        <Column header="FCer" />

        <Column header="Contractor" />
        <Column header="Qcer" />
        <Column header="FCer" />

        <Column header="Contractor" />
        <Column header="Qcer" />
        <Column header="FCer" />

        <Column header="Contractor" />
        <Column header="Qcer" />
        <Column header="FCer" />

        <Column header="Contractor" />
        <Column header="Qcer" />
        <Column header="FCer" />
      </Row>
    </ColumnGroup>
  );

  return (
    <React.Fragment>
      <div className="user-custom-style">
        <Toast ref={toast} />

        <div>
          <Spin spinning={apiLoading}>
            <Toolbar
              className="mb-4"
              left={() => {
                return (
                  <React.Fragment>
                    <div>
                      <h3 className="m-0">Users</h3>
                    </div>
                  </React.Fragment>
                );
              }}
              right={() => {
                return (
                  <React.Fragment>
                  </React.Fragment>
                );
              }}
            ></Toolbar>
            <DataTable
              ref={dt}
              value={users}
              dataKey="id"
              responsiveLayout="scroll"
              headerColumnGroup={headerGroup}
            >
              <Column field="id" header="ID" />
              <Column field="username" header="Username" />
              <Column field="name" header="Name" />
              <Column
                field="canHandleAutoCad"
                header="AutoCad"
                body={(rowData) => rowData?.canHandleAutocad ? (
                  <React.Fragment>
                    <div style={{
                      justifyContent: "center",
                      display: "flex"
                    }}>
                      <div style={{
                        border: "2px solid red",
                        width: "27px",

                      }}>
                        <b>Yes</b>
                      </div>
                    </div>

                  </React.Fragment>
                ) : (
                  <React.Fragment>No</React.Fragment>
                )}
              />
              <Column field="level" header="Level/ Type" />
              <Column field="dayOffs" header="Unavailable Days" />
              {/* Monday Columns */}
              <Column field="capacityDto.mondayContractorCapacity" header="Contractor" style={{ backgroundColor: COLORS.monday }} />
              <Column field="capacityDto.mondayQcerCapacity" header="Qcer" style={{ backgroundColor: COLORS.monday }} />
              <Column field="capacityDto.mondayFinalCheckerCapacity" header="FCer" style={{ backgroundColor: COLORS.monday }} />

              {/* Tuesday Columns */}
              <Column field="capacityDto.tuesdayContractorCapacity" header="Contractor" style={{ backgroundColor: COLORS.tuesday }} />
              <Column field="capacityDto.tuesdayQcerCapacity" header="Qcer" style={{ backgroundColor: COLORS.tuesday }} />
              <Column field="capacityDto.tuesdayFinalCheckerCapacity" header="FCer" style={{ backgroundColor: COLORS.tuesday }} />

              {/* Wednesday Columns */}
              <Column field="capacityDto.wednesdayContractorCapacity" header="Contractor" style={{ backgroundColor: COLORS.wednesday }} />
              <Column field="capacityDto.wednesdayQcerCapacity" header="Qcer" style={{ backgroundColor: COLORS.wednesday }} />
              <Column field="capacityDto.wednesdayFinalCheckerCapacity" header="FCer" style={{ backgroundColor: COLORS.wednesday }} />

              {/* Thursday Columns */}
              <Column field="capacityDto.thursdayContractorCapacity" header="Contractor" style={{ backgroundColor: COLORS.thursday }} />
              <Column field="capacityDto.thursdayQcerCapacity" header="Qcer" style={{ backgroundColor: COLORS.thursday }} />
              <Column field="capacityDto.thursdayFinalCheckerCapacity" header="FCer" style={{ backgroundColor: COLORS.thursday }} />

              {/* Friday Columns */}
              <Column field="capacityDto.fridayContractorCapacity" header="Contractor" style={{ backgroundColor: COLORS.friday }} />
              <Column field="capacityDto.fridayQcerCapacity" header="Qcer" style={{ backgroundColor: COLORS.friday }} />
              <Column field="capacityDto.fridayFinalCheckerCapacity" header="FCer" style={{ backgroundColor: COLORS.friday }} />

              <Column
                field="action"
                header="Action"
                body={(record) => {
                  return (
                    <React.Fragment>
                      <div className="flex justify-content-center">
                        {permissions.map((permission) => {
                          return permission.name === UPDATE_ASSIGNING_PAGE ? (
                            <div>
                              <Button
                                style={{
                                  backgroundColor: "#037EEA",
                                  borderColor: "#037EEA",
                                }}
                                icon={"pi pi-pencil"}
                                label="Edit"
                                onClick={(event) => {
                                  event.preventDefault();
                                  setSelectedUser(record);
                                  setVisibleProjectCapacityDialog(true);
                                }}
                              />
                            </div>
                          ) : (
                            ""
                          );
                        })}
                      </div>
                    </React.Fragment>
                  );
                }}
              />
            </DataTable>
            <Paginator
              first={basicFirst}
              rows={totalRows}
              totalRecords={totalRecords}
              rowsPerPageOptions={[10, 20, 30]}
              onPageChange={onBasicPageChange}
            ></Paginator>
          </Spin>
        </div>

      </div>
      <UpdateProjectCapacityDialog
        user={selectedUser}
        visible={visibleProjectCapacityDialog}
        setVisible={changeVisibleProjectCapacityDialog}>
      </UpdateProjectCapacityDialog>
    </React.Fragment>
  );
};

export default ProjectCapacity;
